import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WattwatchersMeter } from 'clipsal-cortex-types/src/api/api-ww-meter';

import { RootState } from '../../../../app/store';
import { IntervalType } from '../../../user/account/meter-configuration/meter-chart/MeterConfigurationChart';
import { LongPowerDataType, ShortPowerDataType } from '../../../user/account/meter-configuration/meter-config-types';

export type CircuitDisplayConfig = {
  meterId: string;
  circuitId: string;
  meterIndex: number;
  circuitIndex: number;
  label: string;
  isHidden: boolean;
  color: string;
  isSourceCircuit: boolean;
  circuitType: string;
};

type MeterConfigurationState = {
  meterChartConfig: {
    selectedInterval: IntervalType;
    selectedShortPowerDataType: ShortPowerDataType;
    selectedLongPowerDataType: LongPowerDataType;
    selectedDate: Date;
  };
  circuitDisplayConfigs: CircuitDisplayConfig[];
  hiddenMeterIds: string[];
  isLoaded: boolean;
  memoizedRawMeters: Record<string, WattwatchersMeter>;
};

const initialState: MeterConfigurationState = {
  meterChartConfig: {
    selectedInterval: 'Short Energy',
    selectedShortPowerDataType: 'pRealKw',
    selectedLongPowerDataType: 'pRealKw',
    selectedDate: new Date(),
  },
  circuitDisplayConfigs: [],
  hiddenMeterIds: [],
  isLoaded: false,
  memoizedRawMeters: {},
};

export const meterConfigurationSlice = createSlice({
  name: 'meterConfiguration',
  initialState,
  reducers: {
    setCircuitDisplayConfigs: (state, action: PayloadAction<MeterConfigurationState['circuitDisplayConfigs']>) => {
      return {
        ...state,
        circuitDisplayConfigs: action.payload,
      };
    },
    setHiddenMeterIds: (state, action: PayloadAction<MeterConfigurationState['hiddenMeterIds']>) => {
      return {
        ...state,
        hiddenMeterIds: action.payload,
      };
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoaded: action.payload,
      };
    },
    setMemoizedRawMeters: (state, action: PayloadAction<Record<string, WattwatchersMeter>>) => {
      return {
        ...state,
        memoizedRawMeters: action.payload,
      };
    },
    updateMeterChartConfig: (state, action: PayloadAction<Partial<MeterConfigurationState['meterChartConfig']>>) => {
      return {
        ...state,
        meterChartConfig: {
          ...state.meterChartConfig,
          ...action.payload,
        },
      };
    },
    resetMeterConfigurationState: () => initialState,
  },
});

export const {
  setCircuitDisplayConfigs,
  setHiddenMeterIds,
  setIsLoaded,
  updateMeterChartConfig,
  setMemoizedRawMeters,
  resetMeterConfigurationState,
} = meterConfigurationSlice.actions;

export const selectMeterConfiguration = (state: RootState) => {
  return state.meterConfiguration;
};

export const selectAllMemoizedCircuits = createSelector(
  [(state: RootState) => state.meterConfiguration.memoizedRawMeters],
  (memoizedRawMeters) => {
    return Object.values(memoizedRawMeters)
      .map((m) => m.circuits)
      .flat();
  }
);

export default meterConfigurationSlice.reducer;
