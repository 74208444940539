import React, { useMemo } from 'react';
import {
  Box,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import Highcharts from 'highcharts';
import Chart from 'highcharts-react-official';

import useMeterChartOptions from './use-meter-chart-options';

export function FullScreenMeterChartView({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const backgroundColor = useColorModeValue('white', 'gray.900');
  const modifiedChartOptions = useModifiedChartOptionsForFullScreenView();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent bg={backgroundColor} data-testid="full-screen-chart">
        <ModalCloseButton
          zIndex={99999}
          bottom={isMobileViewport ? '40px' : undefined}
          top={isMobileViewport ? 'ignore' : undefined}
          data-testid="close-full-screen-chart"
        />
        <Box>
          <Chart highcharts={Highcharts} options={modifiedChartOptions} />
        </Box>
      </ModalContent>
    </Modal>
  );
}

/**
 * Custom tooltip formatter to show the time in a more readable format and in landscape mode
 * @returns formatted tooltip
 */
function getFormattedTooltipForLandscapeView() {
  const formattedTime = new Date(this.x).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  });

  return `
    <div style="transform: rotate(90deg);text-align: center;background: #ffffffcc;padding: 5px;border-radius: 5px;border: 1px solid #ccc;display: flex;align-items: flex-start;flex-direction: column;">
        <div style="font-size:12px; font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;"><b>${formattedTime}</b></div>
        ${this.points
          .map((point: any) => {
            return `<div style="font-size:12px; font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;">
                      <span style="color:${point.color};">●</span> <span>${point.series.name}</span>: <span style="font-weight: bold;">${point.y}</span>
                    </div>`;
          })
          .join('')}
    </div>
  `;
}

function useModifiedChartOptionsForFullScreenView() {
  const isMobileViewport = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const { chartOptions } = useMeterChartOptions();
  return useMemo(() => {
    // highcharts does not support landscape mode for the chart, so we need to make some adjustments in a hacky way
    return isMobileViewport
      ? {
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            inverted: true, // flip the chart vertically
            width: window.innerWidth,
            height: window.innerHeight,
            spacingTop: 40,
            spacingBottom: 40,
            spacingLeft: 40,
            spacingRight: 40,
          },
          yAxis: {
            ...chartOptions.yAxis,
            labels: {
              ...chartOptions.yAxis.labels,
              rotation: 90, // rotate the labels to make them look like they are in landscape mode
            },
          },
          xAxis: {
            ...chartOptions.xAxis,
            labels: {
              ...chartOptions.xAxis.labels,
              rotation: 90, // rotate the labels to make them look like they are in landscape mode
            },
          },
          tooltip: {
            ...chartOptions.tooltip,
            useHTML: true,
            backgroundColor: 'transparent',
            borderWidth: 0,
            shadow: false,
            // custom tooltip to show the time in a more readable format and in landscape mode
            formatter: getFormattedTooltipForLandscapeView,
          },
        }
      : {
          ...chartOptions,
          chart: {
            ...chartOptions.chart,
            width: window.innerWidth,
            height: window.innerHeight,
            spacingTop: 40,
            spacingBottom: 40,
            spacingLeft: 40,
            spacingRight: 40,
          },
        };
  }, [isMobileViewport, chartOptions]);
}
